import React from "react"
import { Link } from "gatsby"
import { Highlight, Snippet } from "react-instantsearch-dom"

export const Hit = ({ hit }) => {
  return (
    <div className="w-full h-full mt-4 lg:mt-8 flex flex-col">
      <Link to={`/${hit.path}/${hit.id}`} className="no-underline not-italic">
        <Highlight
          tagName="mark"
          attribute="title"
          hit={hit}
          className="font-bold lg:text-lg"
        />
      </Link>
      <Snippet
        tagName="mark"
        attribute="body"
        hit={hit}
        className="my-2 text-sm lg:text-base"
      />
      <div className="flex items-center pb-4 lg:pb-8 border-b">
        <span className="text-2xs lg:text-xs">{hit.date}</span>
        <Link
          className="rounded-full py-1 px-4 bg-gray-100 w-fit-content text-xs lg:text-sm ml-2 no-underline not-italic"
          to={`/${hit.path}/`}
        >
          {hit.category}
        </Link>
      </div>
    </div>
  )
}
