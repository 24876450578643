import React from "react"

export const HeaderTitle = ({ title, subTitle }) => {
  return (
    <div
      className="
            flex
            items-center
            justify-between
            mb-6
            pb-2
            border-b border-b border-black
            lg:mb-12 lg:border-0
          "
    >
      <h6 className="flex-none text-xl font-bold md:text-2xl no-underline not-italic">
        {title}
      </h6>
      <div className="hidden flex-grow mx-4 w-full h-px bg-black lg:block"></div>
      <h1 className="flex-none text-xs md:text-sm font-bold">{subTitle}</h1>
    </div>
  )
}
