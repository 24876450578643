import React from "react"
import {
  Panel,
  Hits,
  PoweredBy,
  connectStateResults,
} from "react-instantsearch-dom"

import { Hit } from "./Hit"

export const List = props => {
  const { searchState, searchResults, error } = props
  if (searchState && !searchState.query) {
    return null
  }
  return (
    <div>
      <Panel
        header={`「${searchState.query}」の検索結果`}
        footer={<PoweredBy />}
      >
        {error ? <div>{error.message}</div> : null}
        {searchResults && searchResults.nbHits > 0 ? (
          <Hits hitComponent={Hit} />
        ) : (
          <div className="font-bold text-sm my-2 not-italic no-underline">
            No results
          </div>
        )}
      </Panel>
    </div>
  )
}

export const HitList = connectStateResults(List)
