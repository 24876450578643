import * as React from "react"

import { TopPost, HeaderPosts } from "../section"
import { FeatureWrapper } from "../elements"

export const Feature = () => {
  return (
    <FeatureWrapper>
      <TopPost />
      <HeaderPosts />
    </FeatureWrapper>
  )
}
