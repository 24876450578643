import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { BgBlack } from "../../elements"

export const TopPost = () => {
  const data = useStaticQuery(graphql`
    query {
      microcmsBlog(blogId: { eq: "best-approach-for-pimple" }) {
        blogId
        featureImage {
          url
        }
        date(formatString: "Y-MM-DD")
        category {
          name
          id
        }
        title
        excerpt
      }
    }
  `)
  const post = data.microcmsBlog
  const path = post.category[0].id
  const category = post.category[0].name
  return (
    <div className="mb-12 lg:mb-0">
      <Link className="group mb-12 w-full" to={`/${path}/${post.blogId}`}>
        <div className="relative h-64 shadow-lg md:h-128">
          <img
            className="w-full h-full object-cover object-center"
            src={post.featureImage.url}
            alt=""
          />
          <BgBlack />
        </div>
        <div className="flex gap-x-4 items-center my-2">
          <p className="text-xs">{post.date}</p>
          <Link
            to={`/${path}`}
            className="flex
                items-center
                justify-center
                px-4
                py-1
                bg-gray-100
                rounded-full"
          >
            <span className="text-xs">{category}</span>
          </Link>
        </div>
        <Link to={`/${path}/${post.blogId}`}>
          <h3 className="mb-2 text-sm font-medium md:text-base">
            {post.title}
          </h3>
          <p className="text-xs leading-5 md:text-sm md:leading-6">
            {post.excerpt}
          </p>
        </Link>
      </Link>
    </div>
  )
}
