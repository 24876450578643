import React, { useState, useEffect } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

import { SearchModal, SearchModalMobile } from "../section"

export const Header = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      search: file(relativePath: { eq: "search.svg" }) {
        publicURL
      }
      arrow: file(relativePath: { eq: "arrow.svg" }) {
        publicURL
      }
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      line: file(relativePath: { eq: "line.svg" }) {
        publicURL
      }
    }
  `)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    const body = document.querySelector("body")
    body.style.overflow = `${open ? "hidden" : "auto"}`
  })

  return (
    <header className="mb-16 md:mb-24">
      <div
        className="
          flex
          items-center
          justify-between
          mx-auto
          p-6
          max-w-5xl
          lg:pb-0 lg:pt-16
        "
      >
        <Link to="/">
          <img
            className="lg:w-10 h-auto m-0"
            src={data.logo.publicURL}
            alt="logo"
          />
        </Link>
        {/* <!-- メニューリスト --> */}
        <div className="hidden gap-x-10 items-center justify-end w-full lg:flex">
          <div>
            <Link
              className="text-sm not-italic no-underline"
              to="/mens-skincare/"
            >
              メンズスキンケア
            </Link>
          </div>
          <div>
            <Link
              className="text-sm not-italic no-underline"
              to="/mens-cosmetics/"
            >
              メンズコスメ
            </Link>
          </div>
          <div>
            <Link className="text-sm not-italic no-underline" to="/haircare/">
              ヘアケア
            </Link>
          </div>
          <div>
            <Link
              className="text-sm not-italic no-underline"
              to="/hair-removal/"
            >
              メンズ脱毛
            </Link>
          </div>
          <div>
            <Link
              className="flex items-center text-sm not-italic no-underline"
              to="/healthcare/"
            >
              ヘルスケア
              <span className="ml-2">
                <svg
                  className="w-2 fill-current"
                  viewBox="0 0 8 5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4.00033 5L8 1.00033L7.05898 0.0586476L3.99767 3.11996L0.936361 0.0586476L0 1.00033L4.00033 5Z" />
                </svg>
              </span>
            </Link>
          </div>
          {/* <!-- 検索 --> */}
          <div className="flex items-center justify-end w-1/6">
            <SearchModal />
          </div>
        </div>
        {/* <!-- モバイル用 - ハンバーガーメニュー --> */}
        <button
          id="menuBtn"
          onClick={() => setOpen(!open)}
          className={`${open ? "open" : ""} 
                      z-50
                      flex
                      flex-col
                      items-center
                      justify-center
                      w-6
                      h-6
                      cursor-pointer
                      transition
                      duration-500
                      ease-in-out
                      lg:hidden
                    `}
        >
          <div
            id="menuBtnBurger"
            className="
              relative
              w-full
              h-px
              bg-black
              transition
              duration-500
              ease-in-out
            "
          ></div>
        </button>
        {/* <!-- モバイル用 - メニュー --> */}
        <nav
          id="mobileMenu"
          className={`${open ? "" : "invisible opacity-0"}
            absolute
            z-10
            inset-0
            w-screen
            bg-black bg-opacity-40
            transition
            duration-500
            overflow-hidden
          `}
        >
          <div
            className={`${open ? "" : "opacity-0"}
              ml-auto
              w-4/5
              h-full
              bg-black
              transition
              duration-500
            `}
          >
            {/* <!-- SNS --> */}
            <div className="flex gap-x-6 items-center justify-center pt-24">
              <a href="https://instagram.com">
                <img
                  className="w-full h-full"
                  src={data.instagram.publicURL}
                  alt="instagram icon"
                />
              </a>
              <a href="https://twitter.com">
                <img
                  className="w-full h-full"
                  src={data.twitter.publicURL}
                  alt="twitter icon"
                />
              </a>
              <a href="https://line.me/ja/">
                <img
                  className="w-full h-full"
                  src={data.line.publicURL}
                  alt="line icon"
                />
              </a>
            </div>
            {/* <!-- モバイル用 - メニューリスト --> */}
            <div className="mt-12">
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/mens-skincare/"
              >
                メンズスキンケア
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/mens-cosmetics/"
              >
                メンズコスメ
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/haircare/"
              >
                ヘアケア
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/hair-removal/"
              >
                メンズ脱毛
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/healthcare/"
              >
                ヘルスケア
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/bodymake/"
              >
                ボディメイク
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
              <Link
                className="
                  flex
                  items-center
                  justify-between
                  mb-8
                  px-6
                  w-full
                  text-white text-sm
                  not-italic no-underline
                "
                to="/lifestyle/"
              >
                健康
                <span>
                  <img
                    className="w-full h-full"
                    src={data.arrow.publicURL}
                    alt="arrow right"
                  />
                </span>
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="mt-4 px-6 lg:hidden">
        <SearchModalMobile />
      </div>
    </header>
  )
}
