import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { ModalMobile } from "./Modal"

export const SearchModalMobile = () => {
  const data = useStaticQuery(graphql`
    {
      search: file(relativePath: { eq: "search.svg" }) {
        publicURL
      }
    }
  `)
  const [showModalMobile, setShowModalMobile] = useState(false)

  useEffect(() => {
    const body = document.querySelector("body")
    body.style.overflow = `${showModalMobile ? `hidden` : `auto`}`
  })

  const openModalMobile = () => {
    setShowModalMobile(prev => !prev)
  }
  return (
    <>
      <button className="relative w-full" onClick={openModalMobile}>
        <input
          className="
            px-4
            py-2
            w-full
            text-sm
            border border-l-0 border-r-0 border-t-0 border-black
            rounded
            bg-white
          "
          type="text"
          name="search"
          placeholder="キーワードを入力"
          disabled
        />
        <div className="absolute right-8 top-1/2 transform -translate-y-1/2">
          <img
            className="w-4 h-auto"
            src={data.search.publicURL}
            alt="search icon"
          />
        </div>
      </button>

      <ModalMobile
        showModalMobile={showModalMobile}
        setShowModalMobile={setShowModalMobile}
      />
    </>
  )
}
