import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

export const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      line: file(relativePath: { eq: "line.svg" }) {
        publicURL
      }
      tkcholding: file(relativePath: { eq: "tkc-holding.svg" }) {
        publicURL
      }
      adminIcon: file(relativePath: { eq: "admin-icon.jpeg" }) {
        publicURL
      }
    }
  `)
  return (
    <>
      <div className="hidden lg:block">
        {/* ブログ運営者 */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm bg-none">当ブログ運営者</h4>
          </div>
          <div className="flex flex-col items-center justify-center my-8">
            <div className="w-32 h-32 rounded-full">
              <img
                className="w-full h-full object-cover"
                src={data.adminIcon.publicURL}
                alt="運営者　アイコン"
              />
            </div>
            <span className="mt-2 tracking-wide">Mani</span>
          </div>
          <p className="mb-4 text-sm leading-6">
            1996年生まれ・大阪府出身。学生時代はイギリスで過ごし、現在は東京都でWEB系IT会社を経営をしている。
            <br />
            思春期時代にニキビに悩まされ、美容に興味を持つようになる。これまで1,200人以上の男性からの美容に関する悩みを解決してきた実績あり。これからは男性も化粧をするのが当たり前の時代になることを確信しており、日々メンズ美容に関する情報をメディアを通じて発信している。
          </p>
          <div>
            <h4 className="text-center mb-4 mx-auto text-lg font-medium tracking-wide bg-none">
              Stay Tuned
            </h4>
            <div className="flex gap-x-4 items-center justify-center">
              <div className="w-10 h-10">
                <img
                  className="w-full h-full"
                  src={data.instagram.publicURL}
                  alt="インスタグラム"
                />
              </div>
              <div className="w-10 h-10">
                <img
                  className="w-full h-full"
                  src={data.twitter.publicURL}
                  alt="ツイッター"
                />
              </div>
              <div className="w-10 h-10">
                <img
                  className="w-full h-full"
                  src={data.line.publicURL}
                  alt="ライン"
                />
              </div>
            </div>
          </div>
        </div>
        {/* カテゴリー */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm border-0 bg-none">カテゴリー</h4>
          </div>
          <div>
            {/* メンズスキンケア */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/mens-skincare"
            >
              <span className="text-sm">メンズスキンケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* メンズコスメ */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/mens-cosmetics"
            >
              <span className="text-sm">メンズコスメ</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* ヘアケア */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/haircare"
            >
              <span className="text-sm">ヘアケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* メンズ脱毛 */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/hair-removal"
            >
              <span className="text-sm">メンズ脱毛</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* ヘルスケア */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/healthcare"
            >
              <span className="text-sm">ヘルスケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* ボディメイク */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/bodymake"
            >
              <span className="text-sm">ボディメイク</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            {/* 健康 */}
            <Link
              className="
              flex
              items-center
              justify-between
              px-6
              py-4
              no-underline
              not-italic
              border-b border-black"
              to="/lifestyle"
            >
              <span className="text-sm">健康</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        {/* おすすめサイト */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm bg-none">おすすめサイト</h4>
          </div>
          <div className="my-4">
            <div className="mb-2 w-full h-32">
              <a
                className="no-underline not-italic"
                href="https://tkc-holding.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-full h-full object-cover"
                  src={data.tkcholding.publicURL}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
