import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const AboutMe = () => {
  const data = useStaticQuery(graphql`
    {
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      line: file(relativePath: { eq: "line.svg" }) {
        publicURL
      }
      tkcholding: file(relativePath: { eq: "tkc-holding.svg" }) {
        publicURL
      }
      adminIcon: file(relativePath: { eq: "admin-icon.jpeg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="hidden mb-16 mx-auto px-6 max-w-5xl md:mb-24 lg:block">
      <div className="grid gap-x-4 grid-cols-3">
        <div className="col-span-2 p-6 border border-black">
          <h3 className="mb-6 text-center text-lg font-medium">
            当ブログ運営者
          </h3>
          <div className="flex items-center justify-center w-full">
            <div className="w-full h-full rounded-full">
              <img
                className="object-cover"
                src={data.adminIcon.publicURL}
                alt="運営者　アイコン"
              />
            </div>
            <p className="ml-4 text-sm leading-6">
              1996年生まれ・大阪府出身。学生時代はイギリスで過ごし、現在は東京都でWEB系IT会社を経営をしている。
              <br />
              思春期時代にニキビに悩まされ、美容に興味を持つようになる。これまで1,200人以上の男性からの美容に関する悩みを解決してきた実績あり。これからは男性も化粧をするのが当たり前の時代になることを確信しており、日々メンズ美容に関する情報をメディアを通じて発信している。
            </p>
          </div>
        </div>
        <div>
          <h4 className="mb-4 text-center text-lg font-medium">Stay Tuned</h4>
          <div className="flex gap-x-4 items-center justify-center mb-4">
            <div className="w-10 h-10">
              <a href="https://instagram.com">
                <img
                  className="w-full h-full"
                  src={data.instagram.publicURL}
                  alt=""
                />
              </a>
            </div>
            <div className="w-10 h-10">
              <a href="https://twitter.com">
                <img
                  className="w-full h-full"
                  src={data.twitter.publicURL}
                  alt=""
                />
              </a>
            </div>
            <div className="w-10 h-10">
              <a href="https://line.me/ja/">
                <img
                  className="w-full h-full"
                  src={data.line.publicURL}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div>
            <div className="mb-4 w-full h-24">
              <a
                href="https://tkc-holding.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-full h-full object-cover"
                  src={data.tkcholding.publicURL}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
