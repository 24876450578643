import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { BgBlue, LatestWrapper } from "../elements"

export const Latest = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsBlog(limit: 10) {
        edges {
          node {
            blogId
            featureImage {
              url
            }
            date(formatString: "Y-MM-DD")
            category {
              name
              id
            }
            title
            excerpt
          }
        }
      }
    }
  `)
  return (
    <LatestWrapper>
      {data.allMicrocmsBlog.edges.map(edge => {
        const post = edge.node
        const path = post.category[0].id
        const category = post.category[0].name

        return (
          <div className="group mb-12 w-full" key={post.blogId}>
            <Link to={`/${path}/${post.blogId}`}>
              <div className="relative h-64 shadow-lg lg:h-72">
                <img
                  src={post.featureImage.url}
                  alt={`${post.title} サムネイル`}
                  className="w-full h-full object-cover object-center"
                />
                <BgBlue />
              </div>
            </Link>
            <div className="flex gap-x-4 items-center mt-3 mb-2">
              <p className="text-xs">{post.date}</p>
              <Link
                to={`/${path}`}
                className="flex
                items-center
                justify-center
                px-4
                py-1
                bg-gray-100
                rounded-full"
              >
                <span className="text-xs">{category}</span>
              </Link>
            </div>
            <Link to={`/${path}/${post.blogId}`}>
              <h3 className="mb-2 text-sm font-medium md:text-base">
                {post.title}
              </h3>
              <p className="text-xs leading-5 md:text-sm md:leading-6">
                {post.excerpt}
              </p>
            </Link>
          </div>
        )
      })}
    </LatestWrapper>
  )
}
