import React from "react"

export const PickupWrapper = ({ children }) => {
  return (
    <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
      <div
        className="
          flex
          items-center
          justify-between
          mb-6
          pb-2
          border-b border-b border-black
          lg:mb-12 lg:border-0
        "
      >
        <h3 className="flex-none text-xl font-bold md:text-2xl">Pick Up</h3>
        <div className="hidden flex-grow mx-4 w-full h-px bg-black lg:block"></div>
        <h6 className="flex-none text-xs md:text-sm">おすすめ記事</h6>
      </div>
      <div className="px-6 lg:px-0">{children}</div>
    </section>
  )
}
