import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Sidebar } from "../components"
import { BgBlue, ContentWrapper } from "../elements"

export const Category = ({ Title, SubTitle, theCategory, theCategory2 }) => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsBlog {
        edges {
          node {
            blogId
            excerpt
            date(formatString: "Y-MM-DD")
            featureImage {
              url
            }
            title
            category {
              id
              name
            }
          }
        }
      }
    }
  `)
  return (
    <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
      <div
        className="
          flex
          items-center
          justify-between
          mb-6
          pb-2
          border-b border-b border-black
          lg:mb-12 lg:border-0
        "
      >
        <h3 className="flex-none text-xl font-bold md:text-2xl">{Title}</h3>
        <div className="hidden flex-grow mx-4 w-full h-px bg-black lg:block"></div>
        <h6 className="flex-none text-xs md:text-sm">{SubTitle}</h6>
      </div>
      <ContentWrapper>
        <div
          className="
            grid
            gap-x-6 gap-y-12
            col-span-full
            grid-cols-2
            auto-rows-max
            lg:col-span-2
          "
        >
          {data.allMicrocmsBlog.edges.map(edge => {
            const post = edge.node
            const category = post.category[0].id

            if (category === theCategory || category === theCategory2) {
              return (
                <div key={post.blogId}>
                  <Link className="group" to={`/${category}/${post.blogId}`}>
                    <div className="relative mb-2 w-full h-32 shadow-lg md:h-64">
                      <img
                        className="w-full h-full object-cover"
                        src={post.featureImage.url}
                        alt={`${post.title} サムネイル`}
                      />
                      <BgBlue />
                    </div>
                    <span className="text-xs">{post.date}</span>
                    <h2 className="my-2 text-sm font-medium md:text-base">
                      {post.title}
                    </h2>
                    <p className="text-xs leading-5 md:text-sm md:leading-6">
                      {post.excerpt}
                    </p>
                  </Link>
                </div>
              )
            } else {
              return
            }
          })}
        </div>
        <Sidebar />
      </ContentWrapper>
    </section>
  )
}
