import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      line: file(relativePath: { eq: "line.svg" }) {
        publicURL
      }
      tkcholding: file(relativePath: { eq: "tkc-holding.svg" }) {
        publicURL
      }
      adminIcon: file(relativePath: { eq: "admin-icon.jpeg" }) {
        publicURL
      }
    }
  `)

  return (
    <footer className="px-6">
      {/* モバイル用 - フッター */}
      <div className="mb-16 lg:hidden">
        {/* モバイル用 - ブログ運営者 */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm border-0 bg-none">
              当ブログ運営者
            </h4>
          </div>
          <div className="flex flex-col items-center justify-center my-8">
            <div className="w-32 h-32 rounded-full">
              <img
                className="w-full h-full object-cover"
                src={data.adminIcon.publicURL}
                alt="運営者　アイコン"
              />
            </div>
            <span className="mt-2 tracking-wide">Mani</span>
          </div>
          <p className="mb-4 text-sm leading-6">
            1996年生まれ・大阪府出身。学生時代はイギリスで過ごし、現在は東京都でWEB系IT会社を経営をしている。
            <br />
            思春期時代にニキビに悩まされ、美容に興味を持つようになる。これまで1,200人以上の男性からの美容に関する悩みを解決してきた実績あり。これからは男性も化粧をするのが当たり前の時代になることを確信しており、日々メンズ美容に関する情報をメディアを通じて発信している。
          </p>
          {/* SNS */}
          <div className="">
            <h4 className="mb-4 text-lg font-medium border-0 bg-none mx-auto text-center">
              Stay Tuned
            </h4>
            <div className="flex gap-x-4 items-center justify-center">
              <div className="w-10 h-10">
                <a href="https://instagram.com">
                  <img
                    className="w-full h-full"
                    src={data.instagram.publicURL}
                    alt=""
                  />
                </a>
              </div>
              <div className="w-10 h-10">
                <a href="https://twitter.com">
                  <img
                    className="w-full h-full"
                    src={data.twitter.publicURL}
                    alt=""
                  />
                </a>
              </div>
              <div className="w-10 h-10">
                <a href="https://line.me/ja/">
                  <img
                    className="w-full h-full"
                    src={data.line.publicURL}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* モバイル用 - カテゴリー */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm border-0 bg-none">カテゴリー</h4>
          </div>
          <div>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/mens-skincare/"
            >
              <span className="text-xs">メンズスキンケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/mens-cosmetics/"
            >
              <span className="text-xs">メンズコスメ</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/haircare/"
            >
              <span className="text-xs">ヘアケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/hair-removal/"
            >
              <span className="text-xs">メンズ脱毛</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/healthcare/"
            >
              <span className="text-xs">ヘルスケア</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/bodymake/"
            >
              <span className="text-xs">ボディメイク</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
            <Link
              className="
                    flex
                    items-center
                    justify-between
                    px-6
                    py-4
                    border-b border-black
                    not-italic no-underline
                  "
              to="/lifestyle/"
            >
              <span className="text-xs">健康</span>
              <span>
                <svg
                  className="w-3 text-black fill-current"
                  viewBox="0 0 12 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41176 9.17647L12 4.58823L7.41176 -3.69549e-06L6.91271 0.499055L10.6489 4.23529L-2.98023e-07 4.23529V4.94117L10.6489 4.94117L6.91271 8.67741L7.41176 9.17647Z" />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        {/* モバイル用 - おすすめサイト */}
        <div className="mb-8">
          <div className="flex items-center justify-center w-full h-12 bg-black">
            <h4 className="text-white text-sm border-0 bg-none">
              おすすめサイト
            </h4>
          </div>
          <div className="my-4">
            <div className="mb-2 w-full h-32">
              <a
                href="https://tkc-holding.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-full h-full object-cover"
                  src={data.tkcholding.publicURL}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex gap-x-4 items-center justify-center">
          <div className="w-10 h-10">
            <a href="https://instagram.com">
              <img
                className="w-full h-full"
                src={data.instagram.publicURL}
                alt=""
              />
            </a>
          </div>
          <div className="w-10 h-10">
            <a href="https://twitter.com">
              <img
                className="w-full h-full"
                src={data.twitter.publicURL}
                alt=""
              />
            </a>
          </div>
          <div className="w-10 h-10">
            <a href="https://line.me/ja/">
              <img className="w-full h-full" src={data.line.publicURL} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-4 items-center justify-center mb-8">
        <Link to="/" className="text-sm not-italic no-underline">
          ホーム
        </Link>
        <Link to="/about/" className="text-sm not-italic no-underline">
          Mani's Blogとは
        </Link>
        <Link to="/contact/" className="text-sm not-italic no-underline">
          お問い合わせ
        </Link>
        <Link to="/privacy/" className="text-sm not-italic no-underline">
          プライバシーポリシー
        </Link>
      </div>
      <div className="mb-4 text-center">
        <span className="text-sm tracking-wider">©︎ 2021 Mani's Blog</span>
      </div>
    </footer>
  )
}
