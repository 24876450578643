import React, { useRef } from "react"
import { Search } from "./Search"

export const Modal = ({ showModal, setShowModal }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }
  return (
    <>
      {showModal ? (
        <div
          className="bg-black bg-opacity-20 w-screen h-screen fixed inset-0 flex items-center justify-center z-40"
          ref={modalRef}
          onClick={closeModal}
        >
          <Search showModal={showModal} />
        </div>
      ) : null}
    </>
  )
}

export const ModalMobile = ({ showModalMobile, setShowModalMobile }) => {
  const modalRef = useRef()
  const closeModalMobile = e => {
    if (modalRef.current === e.target) {
      setShowModalMobile(false)
    }
  }
  return (
    <>
      {showModalMobile ? (
        <div
          className="bg-black bg-opacity-20 w-screen h-screen fixed inset-0 flex items-center justify-center z-40"
          ref={modalRef}
          onClick={closeModalMobile}
        >
          <Search showModalMobile={showModalMobile} />
        </div>
      ) : null}
    </>
  )
}
