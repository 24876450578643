import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { PickupWrapper } from "../elements"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core"

// install Swiper modules
SwiperCore.use([Navigation])

export const Pickup = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsBlog(
        filter: { category: { elemMatch: { id: { eq: "pickup" } } } }
        limit: 6
      ) {
        edges {
          node {
            blogId
            category {
              name
              id
            }
            featureImage {
              url
            }
            title
          }
        }
      }
    }
  `)
  return (
    <PickupWrapper>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        navigation={true}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          "@1.50": {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {data.allMicrocmsBlog.edges.map(edge => {
          const post = edge.node
          const category = post.category[0].id
          return (
            <SwiperSlide className="group" key={post.blogId}>
              <Link to={`/${category}/${post.blogId}`} className="group">
                <div className="group relative w-full h-64 shadow-lg">
                  <img
                    className="w-full h-full object-cover"
                    src={post.featureImage.url}
                    alt={post.title}
                  />
                </div>
                <h3 className="mt-4 text-sm font-medium md:text-base">
                  {post.title}
                </h3>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </PickupWrapper>
  )
}
