import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Modal } from "./Modal"

export const SearchModal = () => {
  const data = useStaticQuery(graphql`
    {
      search: file(relativePath: { eq: "search.svg" }) {
        publicURL
      }
    }
  `)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const body = document.querySelector("body")
    body.style.overflow = `${showModal ? `hidden` : `auto`}`
  })

  const openModal = () => {
    setShowModal(prev => !prev)
  }
  return (
    <>
      <button onClick={openModal}>
        <img
          className="w-4.5 h-auto m-0"
          src={data.search.publicURL}
          alt="Search icon"
        />
      </button>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
