import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { BgBlue } from "../../elements"

export const HeaderPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsBlog(
        filter: { category: { elemMatch: { id: { eq: "mens-skincare" } } } }
        limit: 4
      ) {
        edges {
          node {
            blogId
            category {
              name
              id
            }
            featureImage {
              url
            }
            title
          }
        }
      }
    }
  `)
  return (
    <div className="grid grid-cols-2 gap-x-8">
      {data.allMicrocmsBlog.edges.map(edge => {
        const post = edge.node
        const category = post.category[0].id
        return (
          <div id={post.blogId} className="mb-8" key={post.blogId}>
            <Link
              className="group relative w-full"
              to={`/${category}/${post.blogId}`}
            >
              <div className="relative h-36 shadow-lg md:h-56">
                <img
                  className="w-full h-full object-cover object-center"
                  src={post.featureImage.url}
                  alt={`${post.title} 画像`}
                />
                <BgBlue />
              </div>
              <h2 className="mt-4 text-sm font-medium md:text-base">
                {post.title}
              </h2>
            </Link>
          </div>
        )
      })}
    </div>
  )
}
