import React from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, Configure } from "react-instantsearch-dom"
import { HitList } from "./HitList"
import "../../styles/Search.css"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export const Search = () => (
  <div className="p-4 lg:p-8 w-mobile lg:w-160 h-fit-content max-h-160 absolute top-16 lg:top-32 right-1/2 transform translate-x-1/2 z-50 bg-white rounded-lg lg:rounded-2xl overflow-y-scroll">
    <InstantSearch indexName="manisblog" searchClient={searchClient}>
      <Configure
        hitsPerPage={20}
        removeStopWords
        analytics
        analyticsTags={["manisblog"]}
      />
      <SearchBox
        showLoadingIndicator
        reset={null}
        translations={{ placeholder: "キーワードを入力" }}
      />
      <HitList />
    </InstantSearch>
  </div>
)
