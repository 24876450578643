import React from "react"

export const BgBlack = () => {
  return (
    <div
      className="
        absolute
        -z-1
        bottom-3
        left-3
        w-full
        h-full
        bg-black
        transform
        group-hover:-translate-x-3 group-hover:translate-y-3
        transition
        duration-500
        ease-in-out
      "
    ></div>
  )
}

export const BgBlue = () => {
  return (
    <div
      className="
        absolute
        -z-1
        inset-0
        w-full
        h-full
        bg-primary-blue
        transform
        group-hover:translate-x-3 group-hover:translate-y-3
        transition
        duration-500
        ease-in-out
      "
    ></div>
  )
}
