import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  HatenaShareButton,
} from "react-share"

export const ShareSns = ({ articleUrl, articleTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      facebookSharePost: file(relativePath: { eq: "facebookSharePost.svg" }) {
        publicURL
      }
      twitterSharePost: file(relativePath: { eq: "twitterSharePost.svg" }) {
        publicURL
      }
      lineSharePost: file(relativePath: { eq: "lineSharePost.svg" }) {
        publicURL
      }
      hatenaSharePost: file(relativePath: { eq: "hatenaSharePost.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <div className="mb-6 grid grid-cols-4 gap-x-2 w-full lg:gap-x-4">
      <FacebookShareButton url={articleUrl} title={articleTitle}>
        <img
          className="w-full h-12 m-0"
          src={data.facebookSharePost.publicURL}
          alt="Facebook シェアアイコン"
        />
      </FacebookShareButton>
      <TwitterShareButton url={articleUrl} title={articleTitle}>
        <img
          className="w-full h-12 m-0"
          src={data.twitterSharePost.publicURL}
          alt="Twittter シェアアイコン"
        />
      </TwitterShareButton>
      <LineShareButton url={articleUrl} title={articleTitle}>
        <img
          className="w-full h-12 m-0"
          src={data.lineSharePost.publicURL}
          alt="Line シェアアイコン"
        />
      </LineShareButton>
      <HatenaShareButton url={articleUrl} title={articleTitle}>
        <img
          className="w-full h-12 m-0"
          src={data.hatenaSharePost.publicURL}
          alt="Hatena シェアアイコン"
        />
      </HatenaShareButton>
    </div>
  )
}
